<template>
  <div>
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import axios from "axios";

export default {
  name: "TepGraficaTurnosFinalizados",
  components: {
    highcharts: Chart,
  },

  data() {
    return {
      chartOptions: {
        xAxis: {
          categories: [],
          title: {
            text: "Empresas",
          },
        },

        yAxis: {
          title: {
            text: "# Turnos",
          },
        },

        exporting: {
          enabled: true,
        },
        title: {
          text: "",
          align: "center",
        },
        subtitle: {
          text: "TURNOS FINALIZADOS",
          align: "center",
        },

        series: [
          {
            type: "column",
            data: [],
            marker: {
              lineWidth: 3,
              fillColor: "white",
            },
            color: {
              linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
              stops: [
                [0, "#ff1c79"],
                [1, "#001871"],
              ],
            },
          },
        ],

        credits: {
          enabled: false,
        },
      },
      respuesta: [],
    };
  },

  methods: {
    async getGrafica() {
      let me = this;
      await axios
        .get("/api/tep/dashboardTepInternos/turnosFinalizados", {
          params: this.$parent.filters,
        })
        .then(async (response) => {
          me.respuesta = response.data;
          await me.graficaTurnosFinalizados(me.respuesta);
        });
    },

    async graficaTurnosFinalizados(resp) {
      let turnosFinalizados = [];
      let empresa = [];
      for (let i = 0; i < this.respuesta.length; i++) {
        if (this.respuesta[i].nEmpresa != 0 && this.respuesta[i].nEmpresa != null) {
          empresa.push(this.respuesta[i].nEmpresa);
          turnosFinalizados.push(parseFloat(this.respuesta[i].cantTurn));
        }
      }
      this.chartOptions.xAxis.categories = empresa;
      this.chartOptions.series[0].data = turnosFinalizados;
      this.chartOptions.series[0].name = "Turnos Finalizados";
    },
  },
};
</script>
